import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  IApartmentEditor,
  IApartmentEditorCounters,
  IApartmentEditorIdName,
  IApartmentStatus,
  ICustomSection,
} from '@smarttypes/hotel';
import { get, isArray, unset } from 'lodash';
import { BehaviorSubject, map, merge, Observable, ReplaySubject, shareReplay, Subject, tap } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { HttpService } from '../../../core/http/http.service';
import { mentionsFields } from './mentions-fields';

@Injectable({
  providedIn: 'root',
})
export class VisitorsApartmentEditorService {
  edit$ = new BehaviorSubject<boolean>(false);
  apartments$ = new BehaviorSubject<IApartmentEditorIdName[] | null>(null);
  currentApartment$ = new BehaviorSubject<IApartmentEditor | null>(null);
  form$ = new Subject<Partial<IApartmentEditor>>();
  newSection$ = new Subject<void>();
  counters$ = new ReplaySubject<IApartmentEditorCounters>();
  private missingInformation = false;

  constructor(
    private readonly httpService: HttpService,
    private translateService: TranslateService,
  ) {}

  get $edit(): Observable<boolean> {
    return this.edit$.asObservable();
  }

  get $currentApartment(): Observable<IApartmentEditor | null> {
    return this.currentApartment$.asObservable().pipe(
      map(apartment => {
        if (apartment) {
          return {
            ...apartment,
            checkInFromTime: apartment?.checkInFromTime?.trim(),
            checkOutUntilTime: apartment?.checkOutUntilTime?.trim(),
          };
        }
        return null;
      }),
    );
  }

  get $apartments(): Observable<IApartmentEditorIdName[] | null> {
    return this.apartments$.asObservable();
  }

  get $updateForm(): Observable<Partial<IApartmentEditor>> {
    return this.form$.asObservable();
  }

  get edit(): boolean {
    return this.edit$.value;
  }

  get apartments(): IApartmentEditorIdName[] | null {
    return this.apartments$.value;
  }

  get currentApartment(): IApartmentEditor | null {
    return this.currentApartment$.value;
  }

  get $counters(): Observable<IApartmentEditorCounters> {
    return this.counters$.asObservable();
  }

  get $newSection(): Observable<void> {
    return this.newSection$.asObservable();
  }

  get missingApartmentsInformation(): boolean {
    return this.missingInformation;
  }

  setEdit(edit: boolean): void {
    this.edit$.next(edit);
  }

  addCustomSection(): void {
    this.newSection$.next();
  }

  setCurrentApartment(apartment: IApartmentEditor): void {
    this.currentApartment$.next(apartment);
  }

  updateFormData(data: Partial<IApartmentEditor>): void {
    this.form$.next(data);
  }

  status(): Observable<IApartmentStatus> {
    return this.httpService.get<IApartmentStatus>(`/apartments/status`);
  }

  list(): Observable<IApartmentEditorIdName[]> {
    return this.httpService.get<IApartmentEditorIdName[]>(`/apartments`).pipe(
      map(apartments =>
        apartments
          .map(apartment => {
            const updatedApartment = {
              ...apartment,
              disabled: !apartment.active,
              propertyName: apartment.propertyName.replace('Apart.', this.translateService.instant('SH.APART')),
            };

            if (!get(environment, 'ui.apartmentEditor.requiredFieldsWarning', true)) {
              unset(updatedApartment, 'emptyRequiredFields');
            }

            return updatedApartment;
          })
          .sort((a, b) => (a?.emptyRequiredFields > b?.emptyRequiredFields ? -1 : 1)),
      ),
      tap(apartments => this.apartments$.next(apartments)),
    );
  }

  hasMissingInformation(): Observable<boolean> {
    return merge(this.list(), this.$apartments).pipe(
      map(apartments => !apartments?.every(a => !a.emptyRequiredFields) ?? false),
      tap(state => (this.missingInformation = state)),
      shareReplay(),
    );
  }

  one(id: string, lang: string, updateCurrent = true): Observable<IApartmentEditor> {
    return this.httpService.get<IApartmentEditor>(`/apartments/${id}?lang=${lang}`).pipe(
      map(rs => this.convertObjectToFormData(rs)),
      tap(rs => {
        if (updateCurrent) {
          this.setCurrentApartment(rs);
        }
      }),
    );
  }

  counter(object: Partial<IApartmentEditor>, lang: string): Observable<IApartmentEditorCounters> {
    this.convertEntryInstructionToBrackets(object);
    return this.httpService
      .post<IApartmentEditorCounters>(`/apartments/counters?lang=${lang}`, object)
      .pipe(tap(counters => this.counters$.next(counters)));
  }

  create(object: Partial<IApartmentEditor>, lang: string): Observable<IApartmentEditor> {
    this.convertEntryInstructionToBrackets(object);
    return this.httpService.post<IApartmentEditor>(`/apartments?lang=${lang}`, object);
  }

  copy(
    object: Partial<IApartmentEditor>,
    lang: string,
    ids: string[],
    copyFullApartment = false,
  ): Observable<IApartmentEditor> {
    this.convertEntryInstructionToBrackets(object);
    return this.httpService
      .post<IApartmentEditor>(`/apartments/copy?lang=${lang}&copyFullApartment=${copyFullApartment}`, {
        data: object,
        ids,
      })
      .pipe(map(rs => this.convertObjectToFormData(rs)));
  }

  copyCustom(section: ICustomSection, lang: string, ids: string[]): Observable<IApartmentEditor> {
    return this.httpService.post<IApartmentEditor>(`/apartments/custom-section/copy?lang=${lang}`, {
      data: section,
      apartmentIds: ids,
    });
  }

  update(id: string, lang: string, object: IApartmentEditor): Observable<IApartmentEditor> {
    this.convertEntryInstructionToBrackets(object);
    return this.httpService.put<IApartmentEditor>(`/apartments/${id}?lang=${lang}`, object).pipe(
      map(rs => this.convertObjectToFormData(rs)),
      tap(rs => {
        this.setCurrentApartment(rs);
      }),
    );
  }

  canDisplayField(field: string) {
    const fields = environment?.ui?.apartmentEditor?.fields;
    return [...(fields?.base ?? []), ...(fields?.stay ?? [])].includes(field);
  }

  private convertMentionsToHtmlForQuill(input: string): string {
    return input.replace(/\n/g, '<p><br></p>').replace(/\[([^\]]+)\]/g, (match, mention) => {
      if (!mentionsFields.includes(mention)) {
        return mention;
      }
      return `<span class="mention" data-denotation-char="#" data-value="${mention}" data-id="${mention}"><span contenteditable="false">${mention}</span></span>`;
    });
  }

  private convertEntryInstructionToBrackets(object: Partial<IApartmentEditor>): Partial<IApartmentEditor> {
    if (object.entryInstruction) {
      object.entryInstruction = this.replaceHtmlMentionsWithBrackets(object.entryInstruction);
    }
    return object;
  }

  private convertObjectToFormData(rs: IApartmentEditor) {
    if (rs?.amenities && isArray(rs.amenities)) {
      rs.amenities = rs.amenities.join(', ');
    }
    if (rs?.checkInFromTime) {
      rs.checkInFromTime = rs.checkInFromTime.trim();
    }
    if (rs?.checkOutUntilTime) {
      rs.checkOutUntilTime = rs.checkOutUntilTime.trim();
    }
    if (rs.entryInstruction) {
      rs.entryInstruction = this.convertMentionsToHtmlForQuill(rs.entryInstruction);
    }
    return rs;
  }

  private replaceHtmlMentionsWithBrackets(content: string): string {
    const div = document.createElement('div');
    div.innerHTML = content;
    const spans = div.querySelectorAll<HTMLSpanElement>('span.mention');
    spans.forEach(span => {
      const mention = span.textContent?.trim()?.replace('#', '') ?? '';
      const newText = `[${mention}]`;
      if (span.parentNode) {
        span.replaceWith(newText);
      }
    });
    return div.innerHTML;
  }
}
