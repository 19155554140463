import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { IStepOption } from 'ngx-ui-tour-core';
import { TourService } from 'ngx-ui-tour-ngx-bootstrap';
import { switchMap } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { AccountService } from './account.service';

interface IStepOptionWithPlacement extends IStepOption {
  placement: string;
  artwork?: string;
  activationRoute?: string;
}

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  #tourSteps: IStepOptionWithPlacement[] = [];

  constructor(
    private readonly tourService: TourService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly accountService: AccountService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.#initializeTourSteps();

    this.accountService
      .$currentUser()
      .pipe(
        tap(() => this.startTour(this.#getActiveSteps())),
        switchMap(() => this.router.events),
        filter(event => event instanceof NavigationEnd),
        map(() => this.#getActiveSteps()),
        filter(steps => steps.length > 0),
      )
      .subscribe(steps => this.startTour(steps));

    this.tourService.setDefaults({ isAsync: true, delayBeforeStepShow: 500, disablePageScrolling: false });

    this.tourService.events$
      .pipe(
        filter(event => event.name === 'stepHide'),
        map(event => get(event, 'value.step', null) as IStepOption | null),
      )
      .subscribe(step => {
        if (step) {
          // @TODO Save to account config object
          localStorage.setItem(`sh-onboarding-${step.anchorId}`, 'true');
        }
      });
  }

  startTour(steps: IStepOption[]) {
    this.tourService.initialize(steps);
    this.tourService.start();
  }

  startTourFromStep(anchorId: string) {
    const step = this.#getActiveSteps().find(s => s.anchorId === anchorId);
    if (step) {
      this.startTour([step]);
    }
  }

  #initializeTourSteps() {
    const endBtnTitle = this.translateService.instant('SH.GOT_IT');
    this.#tourSteps = [
      {
        anchorId: 'aiAssistantMenu',
        title: this.translateService.instant('SH.ONBOARDING.AI_ASSISTANT.TITLE'),
        content: this.translateService.instant('SH.AI_ASSISTANT.PAGE_DESCRIPTION'),
        placement: 'left top',
        popoverClass: 'menu',
        endBtnTitle,
      },
      {
        anchorId: 'translateFeature',
        title: this.translateService.instant('SH.ONBOARDING.AUTO_TRANSLATE.TITLE'),
        content: this.translateService.instant('SH.ONBOARDING.AUTO_TRANSLATE.DESCRIPTION'),
        placement: 'bottom right',
        endBtnTitle,
        activationRoute: '/livechat',
      },
      {
        artwork: '/assets/images/onboarding-artwork-ai-suggestion.gif',
        anchorId: 'aiSuggestion',
        title: this.translateService.instant('SH.ONBOARDING.AI_SUGGESTION.TITLE'),
        content: this.translateService.instant('SH.ONBOARDING.AI_SUGGESTION.DESCRIPTION'),
        placement: 'bottom left',
        endBtnTitle,
        activationRoute: '/livechat',
      },
      {
        anchorId: 'copyFullContentFeature',
        title: this.translateService.instant('SH.ONBOARDING.COPY_FULL_CONTENT.TITLE'),
        content: this.translateService.instant('SH.ONBOARDING.COPY_FULL_CONTENT.DESCRIPTION'),
        placement: 'bottom right',
        endBtnTitle,
        activationRoute: '/guest-area',
      },
      {
        anchorId: 'variableFeature',
        title: this.translateService.instant('SH.ONBOARDING.CUSTOM_FORM_VARIABLE.TITLE'),
        content: this.translateService.instant('SH.ONBOARDING.CUSTOM_FORM_VARIABLE.DESCRIPTION'),
        placement: 'bottom right',
        endBtnTitle,
        activationRoute: '/guest-area',
      },
    ];
  }

  #getActiveSteps(): IStepOption[] {
    return this.#tourSteps.filter(step => !this.#isStepCompleted(step.anchorId));
  }

  #isStepCompleted(anchorId?: string): boolean {
    // console.log(this.router.url)
    return !!localStorage.getItem(`sh-onboarding-${anchorId}`);
  }
}
