import { CrossMongoId } from '@smarttypes/cross-mongo';

export enum AssistantResponseType {
  suggestion = 'suggestion',
  empty = 'empty',
  provideKnowledge = 'provideKnowledge',
}

export enum AssistantEngine {
  assistant = 'assistant',
  completion = 'completion',
}

export interface AssistantResponse<T = never> {
  type: AssistantResponseType;
  engine: AssistantEngine;
  jobId: CrossMongoId;
  systemMessage?: string;
  payload?: T;
}
