import { CompanyTypeEnum, RequestCategoryEnum } from '@smarttypes/core';
import { GuestSorting } from '@smarttypes/hotel';

import { BASE_ENV, GuestTabsEnum, IBaseEnv, UI_GUESTS_FILTERS_DEFAULT } from './base-env';

export const environment: IBaseEnv = {
  ...BASE_ENV,
  poweredBy: 'bluemall.png',
  production: true,
  releaseName: 'bluemall-smart-chat',
  releasePhase: 'production',
  registrationEnabled: false,
  showUnlocked: false,
  canEditRooms: true,
  hasLeads: false,
  assistantUrl: 'https://smart-assistant.prod.smarthotelapi.com',
  apiUrl: 'https://core-api.bluemall.place/',
  appViewUrl: 'https://app-views-dvxbybf6wq-uc.a.run.app',
  i18n: {
    dev: false,
    force: false,
    url: 'https://core-api.bluemall.place/translate/app/sh/',
    type: 'smartMall',
  },
  mediaLibrary: 'https://core-api.bluemall.place/media-library',
  jwt: {
    allowedDomains: ['localhost:4200', 'bluemall.web.app', 'admin.bluemall.place', 'bluemall.place'],
    disallowedRoutes: [
      new RegExp(/smart-core-public-api/),
      new RegExp(/account\/login/),
      new RegExp(/account\/refresh/),
      new RegExp(/assets/),
    ],
  },
  modules: {
    pushNotifications: {
      enabled: true,
    },
  },
  requestsTypes: [
    RequestCategoryEnum.services,
    RequestCategoryEnum.maintenance,
    RequestCategoryEnum.standard,
    RequestCategoryEnum.feedback,
  ],
  googleKey: 'AIzaSyCa-i_XDWqSgn83sJ993HOt9CNgr2oqPAU',
  hideUnavailableModulesInMenu: true,
  visitorsImportConfig: {
    checkIntervalTime: 5000,
    timeoutTime: 120000, // 2min
    propertiesTemplate: 'https://docs.google.com/spreadsheets/d/157BskIbBKt3fuw3qwQ1_C4bmv7wLrmNsOid2uWEjSvY',
    apartmentsAccountGuestsTemplate:
      'https://docs.google.com/spreadsheets/d/1lw8QN3gFY6rYO_ZqShoEJrWrQ2xvSrLbCbOWfkeKREA',
    guestsAccountGuestTemplate: 'https://docs.google.com/spreadsheets/d/1RcZ-10oiOoKMQs0czijWCbJQq7l91bMXQ9qo1OJyJ8s',
  },
  homepage: 'settings',
  ui: {
    canShowUpgradeArrow: true,
    showAppsBadge: false,
    flatRoomName: true,
    top: {
      enabled: true,
      account: true,
    },
    sidebar: {
      enabled: true,
      companySwitcher: true,
    },
    unifiedInbox: {
      header: true,
      createRequest: true,
      guests: true,
      sendTemplate: true,
      redirectConversation: true,
      assignUser: true,
    },
    massMessaging: {
      useWhatsAppConversationStarter: true,
      filters: {
        status: [],
        tags: false,
        nationality: false,
        rooms: true,
        building: false,
        department: false,
        guestAddDate: false,
      },
    },
    requests: {
      filters: {
        category: true,
        department: false,
        commonName: false,
        ident: false,
      },
    },
    reservation: {
      showReservationId: false,
    },
    guests: {
      reservationStatus: false,
      nationalityAsLastField: true,
      multipleReservations: false,
      journeyDetails: true,
      editable: true,
      roomSwitcher: true,
      hiddenFilters: {
        rooms: true,
      },
      filters: {
        [CompanyTypeEnum.smartMall]: {
          ...UI_GUESTS_FILTERS_DEFAULT,
          apartmentLeft: false,
          enteredApartment: false,
          communicationChannels: false,
          checkInDate: false,
          checkedOutDate: false,
          guestAreaVisitedDate: true,
          createdAtDate: true,
          reservationStatus: false,
        },
      },
      tabsSorting: {
        [CompanyTypeEnum.smartMall]: {
          [GuestTabsEnum.allActive]: {
            default: GuestSorting.createdDesc,
            allowed: [
              GuestSorting.createdDesc,
              GuestSorting.firstNameAsc,
              GuestSorting.firstNameDesc,
              GuestSorting.lastNameAsc,
              GuestSorting.lastNameDesc,
              GuestSorting.registrationCardSignedAtAsc,
              GuestSorting.registrationCardSignedAtDesc,
            ],
          },
        },
      },
    },
    rooms: {
      edit: false,
      remove: false,
    },
    qrMaterials: {
      identification: false,
      editLink: false,
    },
    registrationFormEditor: {
      qrCodeBox: true,
      signature: true,
    },
    visitorsPlaceModule: {
      enabled: true,
      useAMPM: true,
      allowPublish: false,
    },
    showVisitorRoomInfo: true,
    smartAutomation: {
      forceCheckinEnable: false,
      skipRoomCheck: false,
      settings: {
        enable: true,
        entryInstruction: true,
        channels: true,
      },
    },
  },
  registerPushNotifications: true,
  firebaseCreds: {
    apiKey: 'AIzaSyAT9QybhJWojc742dZA3X35iU4DGfaQm4Y',
    authDomain: 'bluemall-386112.firebaseapp.com',
    projectId: 'bluemall-386112',
    storageBucket: 'bluemall-386112.firebasestorage.app',
    messagingSenderId: '24082476438',
    appId: '1:24082476438:web:6234e3233fddb9c292c1b7',
    measurementId: 'G-MM9860717S',
  },
  vapidKey: 'BKRc5DKUFKswvnZeuEDW-Q_O13c4GahmAGFMzDCRrcJA4dVBdB0U7dbO_0372xaU_wnYx5cuIGAmO30PkThliKw',
};
